import * as React from "react"

import Layout from "../components/Layout/"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Contato enviado" />
    <h1 style={{ textAlign: "center" }}>Enviado com sucesso</h1>
    <p style={{ textAlign: "center" }}>
      Em breve entraremos em contato com você.
    </p>
  </Layout>
)

export default NotFoundPage
